.bursts {
  background-color: $color-4;
  padding: 10px 0;
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
              & > div {
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    //@extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3, p {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
                text-shadow: 0 0 5px $color-black;
            }
            h3 {
                @extend .font-1-medium;
                font-size: 3vw;
                font-weight: 400;
                text-transform: uppercase;
            }
            p {
                @extend .font-1-normal;
                font-size: 2vw;
                font-weight: 400;
                line-height: 1.5;
                margin-bottom: 0;
            }
            &.burst-center {
              h3, p {
                  text-align: center;
                  color: $color-3;
                  transition: all 0.2s ease-out; // OUT effect
              }
            }
            &.burst-left {
              .text-container {
                & > div {
                      @extend .flex-column;
                      @extend .justify-center;
                      @extend .items-center;
                      transition: all 0.2s ease-out; // OUT effect
                  }
              }
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, p {
                color: $color-3;
                transition: all 0.3s ease; // IN effect
                text-shadow: 0 0 5px $color-black;
            }
            &.burst-center {
              h3, p {
                  color: $color-white;
                  transition: all 0.3s ease; // IN effect

              }
            }
        }
    }
}
@media screen and (max-width: $size-slider-breakpoint) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    text-align: center;
                    font-size: 2rem;
                }
                p {
                    text-align: center;
                    font-size: 1.5rem;
                }
            }
        }
    }
}
