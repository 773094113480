
/** to add hover effect on icon **/
.filter-hover {filter: invert(79%) sepia(60%) saturate(6121%) hue-rotate(20deg) brightness(89%) contrast(94%);}
.filter-black {filter: none;}
.filter-normal {filter: invert(100%) sepia(19%) saturate(254%) hue-rotate(303deg) brightness(115%) contrast(100%);}

.icon {
  position: relative;
    a {
        background-color: inherit;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            right: 0;
            left: 0;
            bottom: 0;
            transition: all 0.2s ease-in;
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;

            }
        }
    }
}
