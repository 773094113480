.btn {
    background-color: #d52136;
    color: $color-white;
    border: none;
    border-radius: 0;
    padding: 5px;
    font-weight: 500;
    font-size: $font-size-24;
    width: 295px;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}

.btn.filedoc {
    background-color: #535353;
    color: $color-white;
    border: none;
    border-radius: 0;
    padding: 5px;
    font-weight: 500;
    font-size: $font-size-24;
    width: 295px;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: #d52136;
    }
}
