// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
 @import '../vendors/owl-carousel2/owl.carousel';
 @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

section.section-text-image {
    padding: 60px 0 100px 0;
    background-image: url('../images/accueil_section02_pho01.jpg');
    background-position: center left 94%;
    background-size: auto 60%;
    background-repeat: no-repeat;
    @media screen and (max-width: 1200px)
    {
        background-size: auto 40%;
        @media screen and (max-width: $size-xs-max)
        {
            padding-bottom: 300px;
            //background-size: auto 60%;
            background-position: bottom left 94%;
        }
    }

    h3 {
        font-size: $font-size-28;
        font-weight: 600;
        color: $color-4;
        text-transform: inherit;
    }
    p {
      font-size: $font-size-18;
      line-height: 1.8;
    }
}


section.section-nouveautes {
    background-color: $color-4;
    padding: 20px 20px 40px 20px;

    div.title-section-box {
        h3 {
            color: $color-white;
            font-weight: 400;
            text-align: center;
            padding-bottom: 30px;
            font-size: $font-size-40;
        }
    }

    div.product {
        margin: 5px;
        transition: .3s;
        z-index: 1;
        width: 18%;
        img {
            width: 100%;
        }
        div.title-wrapper{
            background-color: $color-1;
            padding: 10px;
            transition: .3s;
            h4 {
              color: $color-white;
              text-align: center;
              transition: .3s;
            }
        }
        &:hover {
          z-index: 3;
            transform: scale(1.2);
            transition: .3s;
            div.title-wrapper{
                background-color: $color-white;
                transition: .3s;
                h4 {
                  color: $color-black;
                  transition: .3s;
                }
            }
        }
        @media screen and (max-width: 1200px){
            width: inherit;
        }
    }
}

section.section-logos {
    padding: 30px 60px;
    background-color: #ababab;
}

section.section-acreditations {
    background-color: $color-grey-dark;
    p {
      text-align: center;
      color: $color-white;
      white-space: nowrap;
      font-size: $font-size-24;
    }
    @media screen and (max-width: $size-nav-toggle-breakpoint){
         flex-direction: column;
         justify-content: center;
         align-items: center;
         @media screen and (max-width: $size-xs-max){

           .left, .right {
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
           }
            .txt {
                flex: 100%;
            }
         }
    }
}

#section-01-apropos {
    padding: 60px 5%;
    background: $color-grey-dark;
    p {
        color: $color-white;
        line-height: 1.5;
        font-size: $font-size-18;
    }
    img {
      margin: auto;
    }
    h4 {
      color: $color-white;
      font-size: $font-size-26;
      padding-bottom: 20px;
      font-weight: 600;
    }
}
/*****************************************************/
/*  POPUP
/*****************************************************/
.product-popup {
    .product-wrapper {
        @extend .flex;
        // @extend .flex-wrap;
        @extend .justify-start;
        @extend .items-center;
        .product-image {
            //flex: 1;
        }
        .product-info {
            //max-height: 90vH;
        }
    }
    position: relative;
    background: white;
    padding: 20px;
    width: auto;
    max-width: 90%;
    margin: 20px auto;
    img.mfp-img {
        padding: 0;
        width: 100%;
    }
    .title {
        padding: 20px 0;
        color: $color-1;
        font-weight: 700;
        font-size: $font-size-26;
        text-transform: uppercase;
    }
    .owl-item.active{
        cursor: pointer;
        transition: .3s;
        border: 2px solid $color-white;
        &.current {
            border: 2px solid $color-1;
            opacity: .6;
            transition: .3s;
        }
    }

    div.info {
        @extend .flex;
        @extend .items-center;
        @extend .justify-between;
    }
    div.border-right {
        border-right: 3px solid $color-1;
    }
    div.border-bottom {
        border-bottom: 5px solid $color-1;
    }
    div.border-top {
        border-top: 5px solid $color-1;
    }
    div.description {
        //@extend .font-1;
        color: $color-grey-light;
        font-size: 20px;
        line-height: 28px;
        text-align: justify;
        p , li {
            line-height: 1.3;
            font-weight: 400;
            font-size: $font-size-18;
            color: $color-grey;
            text-transform: inherit;
        }
        h3 {
            //color: $color-black;
            font-weight: 400;
            color: $color-grey;
            font-size: $font-size-24;
        }
    }
    .prix {
        color: $color-1;
        font-weight: 600;
        font-size: $font-size-30;
    }
    @media screen and (max-width: $size-xs-max) {
        .sku, .title, .categories, div.description {
            font-size: $font-size-16;
            text-align: left;
            padding: 0;
        }
        .product-wrapper {
            flex-direction: column;
            .product-info .info {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.mfp-close-btn-in .mfp-close {
    // color: $color-yellow;
    height: 24px;
    line-height: 24px;
    width: 24px;
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-text {
    padding: 15px 5% 100px 5%;
    background: $color-4;
    h2 {
        font-weight: 500;
        font-size: $font-size-42;
        color: $color-white;
    }
    .text {
        padding: 25px 5% 0 5%;
        p {
            font-size: $font-size-20;
            color: $color-white;
            line-height: 2;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 15px 5% 50px 5%;
    }
}

#section-produits {
    padding: 50px 4%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .produits {
        min-width: 250px;
        margin: 25px 5px;
        border-top: 6px solid #d52136;
        border-bottom: 6px solid #d52136;
        padding: 10px 0;
        h4 {
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-4;
            text-transform: uppercase;
            text-align: center;
            padding: 5px 0 25px 0;
        }
        .logo {
            padding-bottom: 5px;
            .img-responsive {
                margin: auto;
            }
        }
        .image {
            position: relative;
            .overlay {
                background-color: rgba(213,33,54, 0.4);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
            }

            h4 {
                color: #d52136;
            }
        }
    }
}

/*******************************************************************************
* PRODUITS - FICHE
*******************************************************************************/






/*******************************************************************************
* FABRICATION
*******************************************************************************/

#section-text.fabrication {
    padding: 75px 5% 25px 5%;
    p {
        font-size: $font-size-20;
        color: $color-white;
        text-align: center;
    }
}

#section-images {
    margin: 25px 5%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;
    border-bottom: 6px solid #d52136;
    .image {
        width: 24%;
    }
    @media screen and (max-width: 1675px) {
        .image.mr4 {
            margin-right: 0.5rem;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        .image {
            width: 48%;
            margin: 2px;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        .image {
            width: 100%;
            margin: 0 0 15px 0;
        }
    }
}

#section-list {
    padding: 25px 5% 100px 5%;
    h4 {
        font-weight: 600;
        font-size: $font-size-32;
        color: $color-4;
        @media screen and (max-width: 360px) {
            font-size: 1.5rem;
        }
    }
    .list {
        display: flex;
        justify-content: space-around;
        padding-top: 25px;
        ul {
            list-style: none;
            li {
                font-weight: 600;
                font-size: $font-size-20;
                color: $color-4;
                position: relative;
                line-height: 1.5;
                padding-bottom: 5px;
                @media screen and (max-width: 360px) {
                    font-size: 0.9rem !important;
                }
            }
            li::before {
                content: "\2022";
                color: #d52136;
                display: inline-block;
                position: absolute;
                top: 0;
                left: -20px;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            flex-direction: column;
            .center {
                margin: -10px 0;
            }
             li {
                font-size: 1rem !important;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            ul {
                padding: 0;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        li {
            font-size: 0.9rem !important;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            font-size: 0.7rem !important;
        }
    }
}

/*******************************************************************************
* PIÈCES ET ACCESSOIRES
*******************************************************************************/

#section-text.pieces {
    padding: 75px 5%;
    .text {
        padding: 0 5%;
    }
}
#section-produits.pieces {
    .produits {
        width: 19%;
        .description {
            p {
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-4;
                margin: 0;
                line-height: 1.3;
                @media screen and (max-width: $size-xs-max) {
                    font-size: 1rem;
                }
            }
        }
        @media screen and (max-width: $size-sm-max) {
            width: 48%;
        }
        @media screen and (max-width: $size-xs-max) {
            width: 88%;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-text-image {
    display: flex;
    align-items: center;
    padding: 100px 5%;
    background: $color-4;
    .left-side {
        width: 50%;
        margin-right: 50px;
        p {
            font-size: $font-size-20;
            color: $color-white;
        }
    }
    .right-side {
        display: flex;
        width: 50%;
    }
    @media screen and (max-width: 1450px) {
        flex-direction: column;
        .left-side {
            width: 100%;
            margin-right: 0;
            margin-bottom: 50px;
        }
        .right-side {
            width: 70%;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        .right-side {
            width: 100%;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 65px 5%;
        .right-side {
            flex-direction: column;
            .image.mr2 {
                margin: 0 0 15px 0;
            }
        }
    }
}

#section-parallax {
    background: url("../images/services_section02_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 385px;
    border-bottom: 10px solid $color-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h4 {
        text-align: center;
        font-weight: 600;
        font-size: $font-size-38;
        color: #d52136;
        line-height: 1.3;
    }
    @media screen and (max-width: 1450px) {
        padding: 0 2%;
    }
    @media screen and (max-width: $size-md-max) {
        h4 br {
            display: none;
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-emplois {
    padding: 100px 10% 60px 10%;
    background: $color-4;
    @media screen and (max-width: $size-md-max) {
       padding: 100px 5% 60px 5%;
    }
    @media screen and (max-width: $size-xs-max) {
       padding: 60px 5%;
    }
    p {
        font-size: $font-size-20;
        line-height: 1.5;
        color: $color-white;
    }
    section.emplois-liste {
        padding: 75px 75px 0 75px;
        @media screen and (max-width: 1024px) {
           padding: 75px 0 0 0;
        }
        .item-poste.emploi {
            margin-bottom: 60px;
            h3 {
                font-weight: 500;
                font-size: $font-size-28;
                color: $color-white;
                text-transform: initial;
                padding-bottom: 25px;
            }
            h4 {
                font-weight: 500;
                font-size: $font-size-24;
                color: $color-white;
                padding-bottom: 15px
            }
            ul {
                padding-left: 50px;
                padding-bottom: 50px;
                @media screen and (max-width: $size-xs-max) {
                   padding-left: 30px;
                }
                li {
                    font-size: $font-size-20;
                    line-height: 1.5;
                    padding-bottom: 5px;
                    color: $color-white;
                }
            }
        }
    }
}

#section-form {
    padding: 50px 0;
    .label-wrapper {
        width: 300px;
        @media screen and (max-width: 1024px) {
           width: 200px;
        }
        label.control-label {
            font-weight: 500;
            font-size: $font-size-24;
            color: $color-4;
            @media screen and (max-width: 1024px) {
               font-size: 1rem;
            }
        }
    }
    input {
        background: #c1c1c1;
        height: 35px;
        border: none;
    }
    textarea {
        background: #c1c1c1;
    }
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#section-formulaire {
    background: $color-4;
    padding: 100px 10%;
    .text-intro {
        padding-bottom: 50px;
        p {
            font-weight: 500;
            font-size: $font-size-20;
            margin: 0;
            color: $color-white;
            line-height: 1.5;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-infos {
    padding: 75px 10% 25px 10%;
    background: $color-4;
    display: flex;
    .left-side {
        width: 70%;
        margin-right: 5%;
        display: flex;
        align-items: center;
    }
    .icon {
        width: 32%;
    }
    .icon.first {
        width: 24%;
        .img-responsive {
            margin: auto;
        }
    }
    .text {
        h4 {
            font-weight: 500;
            font-size: $font-size-28;
            color: $color-white;
        }
    }
    .right-side {
        width: 50%;
        display: flex;
        align-items: center;
        a {
            font-weight: 500;
            font-size: $font-size-28;
            color: #e6cc30;
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

#section-heures-map {
    padding: 25px 10% 75px 10%;
    background: $color-4;
    display: flex;
    .left-side {
        width: 50%;
        margin-right: 5%;
        display: flex;
        .icon {
            width: 28%;
        }
        h3 {
            font-weight: 500;
            font-size: $font-size-32;
            color: $color-white;
            padding-bottom: 25px;
        }
        .jours-heures {
            display: flex;
            p {
                font-size: $font-size-20;
                color: $color-white;
            }
            .jour {
                width: 50%;
            }
        }
    }
    .right-side {
        width: 50%;
    }
}

div.decors {
    .item {
      background: $color-4;
      text-align: center;
      margin: 5px;
    }
    .owl-nav {

        left: 0;
        right: 0;
        //opacity: 0;
        transition: all .3s;
    }

    /* previus/next slider control*/
    .owl-prev,
    .owl-next {
          width: 45px;
          height: 45px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          overflow: hidden;
          transition: all 1s ease;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          transition: .3s;
          opacity: .6;
          &:hover {
              opacity: 1;
              transition: .3s;
          }
    }

    /* previus slider control*/
    .owl-prev {
        left: 10px;
        background-image: url('../images/accueil_section03_flechegauche.png');
    }
    /* next slider control*/
    .owl-next {
        right: 10px;
        background-image: url('../images/accueil_section03_flechedroite.png');
    }
}

/*******************************************************************************
* MOBILE
*******************************************************************************/

@media screen and (max-width: 1880px) {
    #section-formulaire {
        padding: 100px 8%;
    }
    #section-formulaire .col-xs-12.col-sm-7.mr4 {
        margin-right: 3.2rem;
    }
    #section-formulaire form label {
        font-size: 1.1rem;
    }
    #section-formulaire form .list-checkBox label {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1720px) {
    #section-formulaire {
        padding: 100px 6%;
    }
}

@media screen and (max-width: 1615px) {

    #section-infos .left-side {
        width: 50%;
    }
    #section-infos .text h4 {
        font-size: 1.2rem;
    }
    #section-infos .icon.first {
        width: 29%;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-6.big {
        width: 100%;
        padding-bottom: 25px;
    }
    #section-formulaire .text-intro p br {
        display: none;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-5.pt8.mr16 {
        width: 80%;
    }
    #section-formulaire .form-group.col-xs-12.bouton {
        padding-left: 0;
    }
}

@media screen and (max-width: 1500px) {

    #section-infos {
        padding: 75px 5% 25px 5%;
    }
    #section-heures-map {
        padding: 25px 5% 75px 5%;
    }
    #section-formulaire .col-xs-12.col-sm-7.mr4 {
        margin-right: 0;
        width: 100%;
        padding-bottom: 25px;
    }
    #section-formulaire .pt3.pb8 {
        display: block !important;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-6.date {
        padding-bottom: 25px;
        padding-top: 25px;
    }
    #section-formulaire .form-group.autres {
        margin-top: 25px;
    }
}

@media screen and (max-width: 1425px) {

    #section-heures-map .left-side .icon {
        width: 32%;
    }
    #section-infos .icon.first {
        width: 36%;
    }
}

@media screen and (max-width: $size-md-max) {


}

@media screen and (max-width: 1200px) {

    #section-infos {
        flex-direction: column;
    }
    #section-infos .left-side {
        width: 100%;
        margin: 0 0 25px 0;
    }
    #section-infos .right-side {
        width: 100%;
    }
    #section-infos .icon.first, #section-infos .icon {
        width: 20%;
    }
    #section-infos .icon.first .img-responsive {
        margin: initial;
        margin-left: 25px;
    }
    #section-heures-map .left-side .icon {
        width: 28%;
        margin-right: 25px;
    }
    #section-heures-map .left-side h3 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1024px) {

    #section-heures-map .left-side h3 {
        font-size: 1.3rem;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-6.pt8 {
        width: 100%;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-5.pt8.mr16 {
        width: 80%;
        margin: 0;
    }
}

@media screen and (max-width: $size-sm-max) {

    #section-heures-map {
        flex-direction: column;
    }
    #section-heures-map .left-side {
        width: 100%;
        margin: 0 0 25px 0;
    }
    #section-heures-map .right-side {
        width: 100%;
    }
    #section-infos .icon.first, #section-infos .icon {
        width: 28%;
    }
    #section-heures-map .left-side .icon {
        width: 25%;
        margin-right: 25px;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-6.date {
        padding-top: 10px;
        width: 75%;
    }
}

@media screen and (max-width: $size-xs-max) {

    #section-infos .left-side, #section-infos .right-side, #section-heures-map .left-side {
        flex-direction: column;
    }
    #section-infos .icon.first .img-responsive, #section-infos .icon .img-responsive, #section-heures-map .left-side .icon .img-responsive {
        margin: auto;
        padding-bottom: 25px;
    }
    #section-infos .icon.first, #section-infos .icon, #section-heures-map .left-side .icon {
        width: 100%;
    }
    #section-infos .text {
        text-align: center;
    }
    #section-heures-map .left-side .jours-heures {
        justify-content: center;
    }
    #section-heures-map .left-side h3 {
        font-size: 1.5rem;
        text-align: center;
    }
    #section-formulaire {
        padding: 65px 3%;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-10 {
        padding: 0;
    }
    #section-formulaire form .list-checkBox label {
        width: 100%;
        position: relative;
        margin-bottom: 35px
    }
    #section-formulaire form input[type="checkbox"] {
        position: absolute;
        right: 50%;
    }
    #section-formulaire .form-group.autres {
        margin-top: 0;
        width: 100%;
    }
    #section-formulaire .form-group.autres label.control-label {
        margin-bottom: 15px;
    }
    #section-formulaire form label.title-list.title-checkbox {
        font-size: 1.2rem;
        padding-bottom: 15px;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-5.pt8.mr16 {
        width: 100%;
    }
    #section-formulaire .form-group.col-xs-12.col-sm-6.date {
        padding-top: 0;
        width: 100%;
    }
}

@media screen and (max-width: 360px) {

    #section-formulaire form label {
        font-size: 1rem;
    }
    #section-formulaire form input[type="checkbox"] {
        right: 47%;
    }
}
